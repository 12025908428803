<template>
  <b-modal
      v-model="show"
      content-class="package-modal rounded-8 product-detail-modal"
      dialog-class="wameed-modal-md"
      no-fade
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t('orders.products.title') }}
        </h3>
      </div>
    </template>


    <div class="body">


      <div class="product-info">
        <div class="product-info_data">

          <!--          <img src="https://backend.talqa.wameedprojects.com/uploads/amirali-mirhashemian-sc5sTPMrVfk-unsplash.jpg"-->
          <!--               alt="">-->
          <img v-if="detail.image && showImage" :src=detail.image :alt=detail.name @error="onError" class="product-img">
          <div v-else class="product-img">
                <span>
        {{ trimName[0] }}
               </span>
          </div>
        </div>

        <div class="product-info_data_col">
          <div class="name">{{ detail.name }}</div>
          <div class="desc">{{ detail.description }}</div>
          <div class="option">
            <check-price
                :dis-price="prodPrice"
                :price="prodOldPrice"
            />
            <div class="counter">
              <div class="counter-btn" @click="plusAction">
                <o-plus-icon/>
              </div>
              <div class="number">
                {{ form.counter }}
              </div>
              <div class="counter-btn" :class="minusDisabled?'disable':''" @click="minusAction">
                <o-minus-icon/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>

      <div>
        <div class="options-info">
          <div class="flex">
            <div class="name"> {{ $t('orders.products.modal.sizes') }}</div>
            <div class="type badge badge-light-danger badge-pill px-3">{{ $t('orders.products.modal.required') }}</div>
          </div>
          <div class="desc">{{ $t('orders.products.modal.choose-one') }}</div>
        </div>

        <div class="product-options">
          <label class="custom-input input-bottom-border" v-for="size in detail.sizes" :key="size.id">
            <input :value="JSON.stringify(size)"
                   type="radio"
                   name="size"
                   @change="chooseProductSize"
            />
            <span class="text-reg-16">{{ size.name }}</span>
            <span class="ml-auto text-reg-16">
                <span v-if="size.price==0">
                {{ $t('common.free') }}
              </span>
              <span v-else>
                {{ formatNumber(size.price) }}
              </span>
            </span>
          </label>
        </div>

      </div>

      <div class="divider" v-if="detail.types && detail.types.length>0"/>
      <div v-if="detail.types && detail.types.length>0">
        <div class="options-info">
          <div class="flex">
            <div class="name">{{ $t('orders.products.modal.types') }}</div>
            <div class="type badge badge-light-font-sub badge-pill px-3">{{
                $t('orders.products.modal.optional')
              }}
            </div>
          </div>
          <div class="desc">{{ $t('orders.products.modal.choose-one') }}</div>
        </div>

        <div class="product-options">
          <label class="custom-input input-bottom-border" v-for="type in detail.types" :key="type.id">
            <input :value="JSON.stringify(type)"
                   type="radio"
                   name="type"
                   @change="chooseProductType"
            />
            <span class="text-reg-16">{{ type.name }}</span>
            <span class="ml-auto text-reg-16">
                <span v-if="type.price==0">
                {{ $t('common.free') }}
              </span>
              <span v-else>
                +{{ formatNumber(type.price) }}
              </span>
            </span>
          </label>
        </div>

      </div>

      <div class="divider" v-if="detail.addons && detail.addons.length>0"/>
      <div v-if="detail.addons && detail.addons.length>0">
        <div class="options-info">
          <div class="flex">
            <div class="name">{{ $t('orders.products.modal.addons') }}</div>
            <div class="type badge badge-light-font-sub badge-pill px-3">{{
                $t('orders.products.modal.optional')
              }}
            </div>
          </div>
          <div class="desc">{{ $t('orders.products.modal.choose-multi') }}</div>
        </div>
        <div class="product-options">
          <label class="custom-input input-bottom-border" v-for="addon in detail.addons" :key="addon.id">
            <input :value="JSON.stringify(addon)"
                   type="checkbox"
                   name="addon"
                   @change="chooseProductAddons"
            />
            <span class="text-reg-16">{{ addon.name }}</span>
            <span class="ml-auto text-reg-16 ">
              <span v-if="addon.price==0">
                {{ $t('common.free') }}
              </span>
              <span v-else>
                +{{ formatNumber(addon.price) }}
              </span>
            </span>
          </label>
        </div>

      </div>
    </div>


    <template slot="modal-footer" class="d-none">
      <wameed-btn
          variant="main"
          size="md"
          :title="$t('btn.ok')"
          classes="text-book-18  rounded-12 w-unset px-4 m-0 main-btn"
          block
          @onClick="fillData"
      />
      <wameed-btn
          variant="gray"
          size="md"
          :title="$t('common.cancel')"
          classes="text-book-18  rounded-12 w-unset px-4 m-0 gray-btn"
          block
          @onClick="closeModal"
      />

    </template>
  </b-modal>
</template>

<script>
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm';
import {mapGetters} from "vuex";
import CheckPrice from "@/components/checkPrice";


export default {
  components: {
    CheckPrice,
    WameedBtn,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },


  },

  data() {
    return {
      form: {
        counter: 1,
        price: 0,
        size: null,
        type: null,
        addons: [],
        sizePrice: 0,
        typePrice: 0,
        addonsPrice: 0,
      },
      error: {},

      operation: '',
      showImage: true,
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      detail: 'vendor/orders/getProductDetail',
    }),
    minusDisabled() {
      return this.form.counter <= 1;
    },
    trimName() {
      if (this.detail && 'name' in this.detail) {
        return this.detail.name.toString().trim();
      }
      return '';
    },

    prodPrice() {
      let price = this.form.sizePrice <= 0 ? this.detail.price : this.form.sizePrice;
      return (price - this.detail.dis_price + this.form.typePrice + this.form.addonsPrice) * this.form.counter
    },
    prodOldPrice() {
      let price = this.form.sizePrice <= 0 ? this.detail.price : this.form.sizePrice;
      return (price  + this.form.typePrice + this.form.addonsPrice) * this.form.counter
    },
    show: {
      get() {

        return this.visible;
      },
      set(value) {
        if (!value) {
          this.form = {
            counter: 1,
            price: 0,
            size: null,
            type: null,
            addons: [],
            sizePrice: 0,
            typePrice: 0,
            addonsPrice: 0,
          }
          if (this.operation != 'add')
            this.$emit('close');
        } else {
          this.operation = '';
        }
      },
    },
  },


  methods: {


    plusAction() {
      this.form.counter++;
    },
    minusAction() {
      if (this.form.counter > 1) {
        this.form.counter--;
      }

    },
    filterClick(filter) {
      this.form.filter = filter;
    },
    onError() {
      this.showImage = false;
    },


    chooseProductSize(e) {
      let data = JSON.parse(e.target.value);
      this.form.size = data;
      this.form.sizePrice = data.price
      this.error.size = "";
    },
    chooseProductType(e) {
      let data = JSON.parse(e.target.value);
      this.form.type = data
      this.form.typePrice = data.price
    },
    chooseProductAddons(e) {
      let data = JSON.parse(e.target.value);

      if (!this.form.addons.some(item => item.id === data.id)) {
        this.form.addons.push(data);
        this.form.addonsPrice += data.price;
      } else {
        if ('target' in e && !e.target.checked) {
          this.form.addons = this.form.addons.filter(item => item.id !== data.id);
          this.form.addonsPrice -= data.price;
        }
      }
    },

    fillData() {
      let productId = this.detail.id;


      if (this.form.size == null) {
        alert('must choose size')
        return;
      }
      let addonsIds = this.form.addons.map((item) => item.id);
      let typeId = null
      if (this.form.type && this.form.type.id) {
        typeId = this.form.type.id;
      }

      let data = {
        "product_id": productId,
        "id": this.detail.id,
        "size": this.form.size.id,
        "type": typeId,
        "addons": addonsIds,
        "quantity": this.form.counter,
        "operation" :'add'
      };
      this.operation = 'add';
      this.show = false;
      this.$emit('onComplete', data)


    },
    closeModal() {
      this.show = false;
      this.form = {
        counter: 1,
        price: 0,
        size: null,
        type: null,
        addons: [],
        sizePrice: 0,
        typePrice: 0,
        addonsPrice: 0,
      }
    },
  },

};
</script>
