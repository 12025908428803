<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.general')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"

    />

    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-med-20 text-font-main mb-0">
                {{ $t('settings.general_setting.recommended_meal') }}
              </h5>
            </b-card-header>

            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5 p-6"
                >

                  <div v-if="getData">
                    <div class="recommended-order">
                      <div class="text-value">
                        <div class="text">{{ $t('settings.general_setting.data.vendor') }}</div>
                        <div class="value">{{ getData.vendor }}</div>
                      </div>
                      <div class="text-value">
                        <div class="text">{{ $t('settings.general_setting.data.product') }}</div>
                        <div class="value">{{ getData.product }}</div>
                      </div>
                      <div class="text-value">
                        <div class="text">{{ $t('settings.general_setting.data.maximum_quantity') }}</div>
                        <div class="value">{{ getData.maximum_quantity }}</div>
                      </div>
                      <div class="text-value">
                        <div class="text">{{ $t('settings.general_setting.data.message') }}</div>
                        <div class="value">{{ getData.message }}</div>
                      </div>
                      <div class="text-value">
                        <div class="text">{{ $t('settings.general_setting.data.discount') }}</div>
                        <div class="value">{{ getData.discount }}</div>
                      </div>
                    </div>

                    <div class="additional_form mt-4">

                    <button class="remove-icon m-0" @click="removeRecommendedMeal">
                      <trash-icon/>
                      {{ $t('settings.general_setting.delete_recommended_meal') }}
                    </button>
                    </div>

                  </div>
                  <!-- tempData -->
                  <div class="d-flex flex-wrap">

                    <b-col md="4" class="" v-if="!hasRecommendedMeal && getData==null">
                      <WameedBtn
                          variant="light-light"
                          append-icon="add-filled-circle-icon"
                          classes="text-font-secondary rounded-12 border-font-secondary2 medium-btn mb-5"
                          :title="$t('settings.general_setting.recommended_meal_btn')"
                          @onClick="addRecommendedMeal"
                      />
                    </b-col>

                    <b-col cols="12" md="8" v-if="hasRecommendedMeal && getData==null">


                      <div class="mb-2 additional_form">
                        <b-col class="d-flex align-item-center px-0 flex-wrap">
                          <b-col md="6" class="pl-0">

                            <wameed-dropdown
                                v-model="tempData.vendor"
                                :label="$t('settings.general_setting.vendor.name')"
                                placeholder="-------"
                                variant="disable"
                                :items="getVendors"
                                class="wameed_dropdown"
                                title="name"
                                rules="required"
                                @input="onVendorClick"
                                :no_options="$t('common.no_options')"
                            />

                          </b-col>

                          <b-col md="6" class="pl-0">

                            <wameed-dropdown
                                v-model="tempData.meal"
                                :label="$t('settings.general_setting.vendor.meal')"
                                placeholder="-------"
                                variant="disable"
                                :items="getVendorProducts"
                                class="wameed_dropdown"
                                title="name"
                                rules="required"
                                @selectingOption="onProductClick"
                                :no_options="$t('common.no_options')"
                            />

                          </b-col>

                          <b-col md="6" class="pl-0">

                            <TextInput
                                v-model="tempData.max_quantity"
                                :rules="'required|min_value:'+minValue"
                                field-classes="w-100"
                                :label="$t('settings.general_setting.vendor.max_quantity')"
                                :placeholder="$t('form.text.placeholder')"
                                type="number"
                            />

                          </b-col>

                          <b-col md="6" class="pl-0">
                            <text-input
                                v-model="tempData.message"
                                :label="$t('settings.general_setting.vendor.message')"
                                :placeholder="$t('form.text.placeholder')"
                                name="package-ad"
                                rules="required"
                            />

                          </b-col>

                          <button class="remove-icon m-0" @click="removeRecommendedMeal">
                            <trash-icon/>
                            {{ $t('settings.general_setting.delete_recommended_meal') }}
                          </button>

                        </b-col>


                      </div>
                    </b-col>

                  </div>

                </b-card-body>

                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('common.save')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <meal-detail-modal
        :visible="showProductModal"
        @close="showProductModal =false;tempData.meal=''"
        @onComplete="onChooseProduct"
    />
  </div>
</template>

<script>

import PageHeader from '@/components/wameed/WameedPageHeader.vue';

import {WameedBtn, WameedDropdown, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import {mapGetters} from "vuex";
import MealDetailModal from "@/views/pages/settings/general/components/mealDetailModal";

export default {
  components: {
    MealDetailModal,
    WameedBtn,
    TextInput,

    WameedDropdown,

    PageHeader
  },
  data() {
    return {
      hasRecommendedMeal: false,
      showProductModal: false,
      minValue: 1,
      tempData: {
        vendor: '',
        meal: '',
        max_quantity: '',
        message: '',
      },
      recommended: {}
    };
  },
  watch: {
    recommended(newVal) {
      if (newVal != null) {
        this.minValue = newVal.quantity;
      } else {
        this.minValue = 1;
      }
    },
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/general/getData',
      getVendors: 'admin/settings/general/getVendors',
      getVendorProducts: 'admin/settings/general/getVendorProducts'
    }),

  },
  methods: {

    addRecommendedMeal() {
      this.hasRecommendedMeal = true;
      this.$store.dispatch("admin/settings/general/getVendors");
    },
    removeRecommendedMeal() {
      this.recommended = null;
      this.hasRecommendedMeal = false;

      this.$store.dispatch("admin/settings/general/resetData");
      this.tempData.vendor = '';
      this.tempData.meal = '';
    },
    onVendorClick(item) {
      this.$store.dispatch("admin/settings/general/getVendorProducts", item.id);
    },
    onProductClick(item) {
      this.productModalData = null;
      this.$store.dispatch("vendor/orders/productDetail", this.tempData.vendor.id + "/" + item.id).then(() => {
        this.showProductModal = true
      });
    },
    onChooseProduct(data) {
      this.recommended = data;
      this.showProductModal = false
    },
    goBack() {
      this.$router.push({name: 'settings'});
    },


    submit() {
      let form = {};
      if (!this.recommended || this.recommended == null) {
        form = {operation: 'delete'};
      } else {
        form = {
          operation: 'add',
          vendor_id: this.tempData.vendor.id,
          message: this.tempData.message,
          products: [{...this.recommended, maximum_quantity: this.tempData.max_quantity}]
        }
      }

      this.$store.dispatch("admin/settings/general/recommendedMeal", form).then(()=>{
        this.$store.dispatch('admin/settings/general/get')
      })
    }
  },

  async created() {
    await this.$store.dispatch('admin/settings/general/get');
  }
};
</script>

